import { Stack, Typography, MenuItem, Box } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import TextField from '@watershed/ui-core/components/Form/TextField';
import Button from '@watershed/ui-core/components/Button';
import IconButton from '@watershed/ui-core/components/IconButton';
import ArrowRightIcon from '@watershed/icons/components/ArrowRight';
import HelpIcon from '@watershed/icons/components/Help';
import isNullish from '@watershed/shared-util/isNullish';
import isValidEmail from '@watershed/shared-universal/utils/isValidEmail';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import treePortalToNarniaIllustration from '../report/images/TreePortalToNarnia.png';
import { useFormikContext, Formik, Form } from 'formik';
import Image from '@watershed/ui-core/components/Image';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  DisabledEmailFieldWithChange,
  EmailLoginState,
  LoginState,
  RecipientOrgMetadata,
} from '../../pages/login';
import Callout from '@watershed/shared-frontend/components/Callout';
import AddDomainDialog from '../AddDomainDialog';
import { CompanySurveyHelpRequestContext } from '@watershed/shared-universal/companySurveys/logEvent';
import parseEmail from '@watershed/shared-universal/utils/parseEmail';
import { SUPPORT_EMAIL_LINK } from '@watershed/shared-universal/dashboardRoutes';
import { Trans, Select, useLingui } from '@lingui/react/macro';
import isNotNullish from '@watershed/shared-util/isNotNullish';

export function SupplierNotKickedOffPage({
  recipientName,
  rootName,
  state,
  setState,
}: {
  recipientName: string | null;
  rootName: string | null;
  state: LoginState;
  setState: Dispatch<SetStateAction<LoginState>>;
}) {
  const recipientNameExists = `${isNotNullish(recipientName)}`;
  return (
    <Stack direction="row" gap={1}>
      <Stack direction="column" gap={2}>
        {rootName ? (
          <Typography variant="h1">
            <Select
              value={recipientNameExists}
              _true={`${rootName}’s supplier engagement program with ${recipientName}
                hasn’t kicked off yet — we’ll reach out when it’s time to get
                started.`}
              other={`${rootName}’s supplier engagement program with you hasn’t kicked
                off yet — we’ll reach out when it’s time to get started.`}
            />
          </Typography>
        ) : (
          <Typography variant="h1">
            <Select
              value={recipientNameExists}
              _true={`${recipientName} is not yet part of a supplier engagement program
                — we'll reach out when it's time to get started.`}
              other={`You are not yet part of a supplier engagement program — we'll
                reach out when it's time to get started.`}
            />
          </Typography>
        )}
        {rootName && (
          <Typography variant="body2">
            <Trans>
              {rootName} has partnered with Watershed to power their supplier
              engagement program.
            </Trans>
          </Typography>
        )}
        <DisabledEmailFieldWithChange
          emailAddress={state.email ?? ''}
          onReset={() =>
            setState({
              state: 'email',
              email: '',
              fetching: false,
            })
          }
          authenticationType="password"
        />
      </Stack>
      <Image
        src={treePortalToNarniaIllustration}
        alt="Tree portal"
        height={300}
      />
    </Stack>
  );
}

export function SupplierLoginPageContainer({
  recipientName,
  rootName,
  LoginComponent,
  state,
  setState,
  submittedHelpRequest,
}: {
  recipientName: string | null;
  rootName: string | null;
  setState: Dispatch<SetStateAction<LoginState>>;
  LoginComponent: () => JSX.Element;
  state: LoginState;
  submittedHelpRequest: boolean;
}) {
  if (state.state === 'email') {
    return (
      <SupplierLoginPage
        {...{
          recipientName,
          rootName,
          LoginComponent,
          state,
          submittedHelpRequest,
        }}
      />
    );
  }

  // Undefined metadata means an org wasn't found for this domain
  else if (state.state !== 'error' && state.metadata === undefined) {
    return (
      <SupplierNotKickedOffPage
        {...{ recipientName, rootName, state, setState }}
      />
    );
  }
  return (
    <SupplierLoginPage
      {...{
        recipientName,
        rootName,
        LoginComponent,
        state,
        submittedHelpRequest,
      }}
    />
  );
}

function loginHeader(
  recipientName: string | null,
  rootName: string | null
): React.ReactNode {
  const combinedValue = `${isNotNullish(recipientName)}${
    isNotNullish(rootName) && rootName !== ''
  }`;
  return (
    <Select
      value={combinedValue}
      _truetrue={`${recipientName} has been invited to ${rootName}'s supplier engagement
        program. Get started below.`}
      _truefalse={`${recipientName} has been invited to a supplier engagement program. Get
        started below.`}
      _falsetrue={`You have been invited to ${rootName}'s supplier engagement program. Get
        started below.`}
      other="You have been invited to a supplier engagement program. Get started below."
    />
  );
}

export function SupplierLoginPage({
  recipientName,
  rootName,
  LoginComponent,
  state,
  submittedHelpRequest,
}: {
  recipientName: string | null;
  state: LoginState;
  rootName: string | null;
  LoginComponent: () => JSX.Element;
  submittedHelpRequest: boolean;
}) {
  return (
    <Stack direction="row" gap={1}>
      <Stack direction="column" gap={2}>
        <Typography variant="h1">
          {loginHeader(recipientName, rootName)}
        </Typography>
        {rootName && (
          <Typography variant="body2">
            <Trans>
              {rootName} has partnered with Watershed to power their supplier
              engagement program.
            </Trans>
          </Typography>
        )}
        <LoginComponent />
        {submittedHelpRequest && state.state === 'email' && (
          <DomainRequestSubmittedCallout rootName={rootName ?? ''} />
        )}
      </Stack>
      <Image
        src={treePortalToNarniaIllustration}
        alt="Tree portal"
        height={300}
      />
    </Stack>
  );
}

export function DomainSelector({
  rootName,
  domains,
  eventContext,
  setSubmittedHelpRequest,
}: {
  rootName: string;
  domains: Array<string>;
  eventContext: CompanySurveyHelpRequestContext;
  setSubmittedHelpRequest: Dispatch<SetStateAction<boolean>>;
}) {
  const formik = useFormikContext<{ domain: string }>();

  // Hack (alex): a workaround since useDialog doesn't work here
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      {dialogOpen && (
        <AddDomainDialog
          rootName={rootName}
          eventContext={eventContext}
          setHasSubmittedState={setSubmittedHelpRequest}
          setDialogOpen={setDialogOpen}
        />
      )}
      <SelectField
        value={formik.values['domain']}
        onChange={(evt) => {
          const domain = evt.target.value as string;
          if (domain === 'notFound') {
            setDialogOpen(true);
            return;
          }
          formik.setFieldValue('domain', domain);
        }}
        id="domain"
        sx={{ width: 300 }}
      >
        {domains.map((domain) => (
          <MenuItem value={domain} key={domain}>
            {domain}
          </MenuItem>
        ))}
        <MenuItem
          value="notFound"
          key="notFound"
          sx={{ background: (theme) => theme.palette.grey20 }}
        >
          <Stack direction="row" gap={1}>
            <HelpIcon sx={{ mt: 0.25 }} size={16} />
            <Trans context="question about website domains">
              Don't see your domain?
            </Trans>
          </Stack>
        </MenuItem>
      </SelectField>
    </>
  );
}

function DomainRequestSubmittedCallout({ rootName }: { rootName: string }) {
  return (
    <Callout
      variant="info"
      title={
        <Trans context="website domain change">
          Domain change request submitted
        </Trans>
      }
      description={
        <Stack direction="column" spacing={1}>
          <Typography variant="body1">
            <Trans>
              Watershed will review your domain change request with {rootName},
              and will email you with updated credentials shortly thereafter.
              Contact Watershed with any questions.
            </Trans>
          </Typography>
          <Box>
            <Button href={SUPPORT_EMAIL_LINK}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                <Trans>Contact Watershed</Trans>
              </Typography>
            </Button>
          </Box>
        </Stack>
      }
    />
  );
}

export function EmailFormWithDomains({
  state,
  recipientMetadata,
  domains,
  onSubmit,
  onError,
  setSubmittedHelpRequest,
}: {
  state: EmailLoginState;
  recipientMetadata: RecipientOrgMetadata;
  domains: Array<string>;
  onSubmit: (email: string) => Promise<void>;
  onError: (error: string) => void;
  setSubmittedHelpRequest: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useLingui();
  const eventContext: CompanySurveyHelpRequestContext = {
    rootName: recipientMetadata.customerName,
    recipientName: recipientMetadata.orgName,
    isDemo: recipientMetadata.demoOrg,
  };

  const [handle, domain] = state.email
    ? parseEmail(state.email)
    : ['', domains[0]];

  return (
    <Formik
      initialValues={{
        username: handle,
        domain,
      }}
      onSubmit={(values) => {
        const email = `${values.username}@${values.domain}`;
        if (
          isNullish(values.username) ||
          isNullish(values.domain) ||
          !isValidEmail(email)
        ) {
          onError('Invalid email');
          return;
        }

        // TODO: URGENT Please fix this by await-ing or void-ing this line.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onSubmit(email);
      }}
    >
      <Form style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Box>
          <Typography variant="h4">
            <Trans context="form header about email and website domain">
              Confirm your email and domain
            </Trans>
          </Typography>
          <Stack flexDirection="row" gap={0.5}>
            <TextField id="username" autoFocus />
            <Typography paddingTop={0.5}>@</Typography>
            <DomainSelector
              domains={domains}
              eventContext={eventContext}
              rootName={recipientMetadata.customerName}
              setSubmittedHelpRequest={setSubmittedHelpRequest}
            />
            <IconButton
              type="submit"
              color="primary"
              title={state.fetching ? t`Loading` : t`Submit`}
              aria-busy={state.fetching}
              size="large"
              data-testid={TestIds.SubmitEmailButton}
            >
              {state.fetching ? (
                <CircularProgress size={24} />
              ) : (
                <ArrowRightIcon
                  color={(theme) => theme.palette.primary.main}
                  size={24}
                />
              )}
            </IconButton>
          </Stack>
        </Box>
      </Form>
    </Formik>
  );
}
